import { useFormData } from '@/composables/useFormData';
import Comment from '@/models/Comment';
import TradeItemCost from '@/models/TradeItemCost';
import TradeItemCostsService from '@/services/TradeItemCostsService';
import { defineStore } from 'pinia';
import { useRepo } from 'pinia-orm';

export const useTradeItemCostsStore = defineStore('trade_item_costs', {
  state: () => ({
    costsForm: useFormData({
      costs: ([] as {
        current_certified: number;
        current_claim: number;
        id: number;
        claim_id: number;
        trade_item_id: number;
        trade_name: string;
        trade_id: number;
      }[]),
    }),
  }),

  getters: {
    models: () => useRepo(TradeItemCost),
  },

  actions: {
    async createTradeItemCost(claimId: Id, tradeItemId: Id, request: ApiRequests['api.v1.trade-item-costs.store']) {
      const [data, error] = await TradeItemCostsService.create(claimId, tradeItemId, request);

      if(data) {
        this.models.save(data.cost);

        if(data.comment) {
          const comment = data.comment;
          const commentsRepo = useRepo(Comment);

          commentsRepo.save(comment);
        }

        return data;
      }

      throw error;
    },

    async updateTradeItemCost(id: Id, request: ApiRequests['api.v1.trade-item-costs.update']) {
      const [data, error] = await TradeItemCostsService.update(id, request);

      if(data) {
        this.models.save(data.cost);

        if(data.comment) {
          const comment = data.comment;
          const commentsRepo = useRepo(Comment);

          commentsRepo.save(comment);
        }

        return data;
      }

      throw error;
    },

    async deleteTradeItemCost(id: Id) {
      const [data, error] = await TradeItemCostsService.delete(id);

      if(data) {
        this.models.destroy(id);

        return data;
      }

      throw error;
    },

    async saveCertifiedCost(
      claimId: Id,
      signedParams: unknown,
      request: ApiRequests['api.v1.claims.certified.store'],
    ) {
      const [data, error] = await TradeItemCostsService.certifiedSingle(claimId, signedParams, request);

      if(data) {
        this.models.save(data.cost);

        if(data.comment) {
          const comment = data.comment;
          const commentsRepo = useRepo(Comment);

          commentsRepo.save(comment);
        }

        return data;
      }

      throw error;
    },

    async saveClaimCost(claimId: Id, signedParams: unknown, request: ApiRequests['api.v1.claims.claim.store']) {
      const [data, error] = await TradeItemCostsService.claimSingle(claimId, signedParams, request);

      if(data) {
        this.models.save(data);

        return data;
      }

      throw error;
    },
  },
});
