import DiaryEntry from '@/models/DiaryEntry';
import DiaryEntryService from '@/services/DiaryEntryService';
import { defineStore } from 'pinia';
import { useRepo } from 'pinia-orm';

export const useDiaryEntriesStore = defineStore('diaryEntries', {
  state: () => ({}),

  getters: {
    models: () => useRepo(DiaryEntry),
  },

  actions: {
    async fetchDiaryEntry(id: Id) {
      const [data, error] = await DiaryEntryService.show(id);

      if(data) {
        this.models.save(data);

        return data;
      }

      throw error;
    },

    async fetchDiaryEntries(projectId: Id) {
      const [data, error] = await DiaryEntryService.index(projectId);

      if(data) {
        this.models.save(data);

        return data;
      }

      throw error;
    },

    async createDiaryEntry(projectId: Id, form: ApiRequests['api.v1.diary-entries.store']) {
      const [data, error] = await DiaryEntryService.create(projectId, form);

      if(data) {
        this.models.save(data);

        return data;
      }

      throw error;
    },

    async updateDiaryEntry(id: Id, form: ApiRequests['api.v1.diary-entries.update']) {
      const [data, error] = await DiaryEntryService.update(id, form);

      if(data) {
        this.models.save(data);

        return data;
      }

      throw error;
    },
  },
});
