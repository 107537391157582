<template>
  <div class="w-[90%] mx-auto">
    <div class="bg-white p-2 mt-8 rounded-lg shadow-lg border border-gray-300">
      <div class="my-5 flex justify-center">
        <h1 class="text-3xl font-semibold justify-center">
          Self Perform
        </h1>
      </div>

      <div class="items-center flex flex-col px-3">
        <div class="w-full justify-center flex flex-col space-y-4 my-2">
          <RouterLink
            v-if="userStore.hasAccessToRoute('MobilePlantDashboard')"
            :to="{ name: 'MobilePlantDashboard', query: { day: date } }"
            class="btn btn--primary-blue shadow-md"
            data-test="new-plant"
          >
            Add New Plant Record
          </RouterLink>

          <RouterLink
            v-if="userStore.hasAccessToRoute('MobileLabourDashboard')"
            :to="{ name: 'MobileLabourDashboard', query: { day: date } }"
            class="btn btn--primary-blue shadow-md"
            data-test="new-labour"
          >
            Add New Labour Record
          </RouterLink>

          <RouterLink
            v-if="userStore.hasAccessToRoute('MobileQuantities')"
            :to="{ name: 'MobileQuantities'}"
            class="btn btn--primary-blue shadow-md"
          >
            Quantities
          </RouterLink>

          <RouterLink
            v-if="userStore.hasAccessToRoute('MobileDashboard')"
            :to="{ name: 'MobileDashboard'}"
            class="btn btn--primary-blue shadow-md"
          >
            Production Data
          </RouterLink>

          <RouterLink
            v-if="userStore.hasAccessToRoute('MobileDiaryIndex')"
            :to="{ name: 'MobileDiaryIndex' }"
            class="btn btn--primary-blue shadow-md"
          >
            Diary Entry
          </RouterLink>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { useNavStore } from '@/store/nav';
import { useUserStore } from '@/store/user';

const date = new Date().toISOString().split('T')[0];
const navStore = useNavStore();

navStore.backRoute = { name: 'MobileProjectList' };

const userStore = useUserStore();
</script>
