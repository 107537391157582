import { DateTime } from 'luxon';
import { Model } from 'pinia-orm';
import { BelongsTo, Bool, Num, Str } from 'pinia-orm/dist/decorators';
import Project from './Project';
import User from './User';
/* --- user header --- */
/* --- end user header --- */

export default class DiaryEntry extends Model {
  static entity = 'App\\Models\\DiaryEntry';
  // fields
  @Num(0)
  declare id: number;
  @Str('')
  declare body: string;
  @Bool(false)
  declare raise_level: boolean;
  @Num(0)
  declare project_id: number;
  @Num(0)
  declare user_id: number;
  @Str('')
  declare created_at: string;
  @Str('')
  declare updated_at: string;
  // relations
  @BelongsTo(() => Project, 'project_id', 'id')
  declare project: Project;
  @BelongsTo(() => User, 'user_id', 'id')
  declare user: User;
  /* --- user code --- */

  get createdAt() {
    return DateTime.fromISO(this.created_at);
  }

  /* --- end user code --- */
}
