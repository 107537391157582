import { route } from '@/ziggy-shim';
import { api } from './LaravelClient';

export default {
  async index(projectId: Id) {
    return await api(route('api.v1.diary-entries.index', projectId), 'get');
  },

  async show(id: Id) {
    return await api(route('api.v1.diary-entries.show', id), 'get');
  },

  async update(id: Id, data: ApiRequests['api.v1.diary-entries.update']) {
    return await api(route('api.v1.diary-entries.update', id), 'patch', {
      data,
    });
  },

  async create(id: Id, data: ApiRequests['api.v1.diary-entries.store']) {
    return await api(route('api.v1.diary-entries.store', id), 'post', {
      data,
    });
  },
};
