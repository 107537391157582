<template>
  <table class="w-full">
    <thead class="">
      <tr class="sticky -top-2 bg-gray-300">
        <th class="py-2 text-left pl-3">
          Contract Works
        </th>

        <th class="text-right pr-3">
          Budget
        </th>

        <th
          v-if="claim?.capture_claim_amount"
          class="text-right bg-green-200/50"
        >
          Current Claim
        </th>

        <th class="text-right bg-green-200/50">
          Current Certified
        </th>

        <th
          v-if="claim?.capture_claim_amount"
          class="text-right pr-3 bg-green-200/50"
        >
          Variance
        </th>

        <th class="text-right bg-green-200/50">
          Previous
        </th>

        <th class="text-right bg-green-200/50 pr-3">
          Cumulative
        </th>

        <th
          v-if="claim?.capture_claim_amount"
          class="text-right bg-blue-300/50"
        >
          Current Claim %
        </th>

        <th class="text-right bg-blue-300/50">
          Current Certified %
        </th>

        <th class="text-right bg-blue-300/50">
          Previous %
        </th>

        <th class="text-right bg-blue-300/50 pr-3">
          Cumulative %
        </th>

        <th class="text-right pr-3 border-r">
          Cost To Complete
        </th>
      </tr>
    </thead>

    <tbody
      v-if="claim"
      class="border-t border-black"
    >
      <TradeRow
        v-for="trade in claim.trades"
        :key="trade.id"
        :trade="trade"
        :claim="claim"
      />

      <tr class="border-black border">
        <td class="pl-3">
          Subtotal
        </td>

        <td class="text-right pr-3">
          {{ australianCurrency(totalCosts.trades.budget) }}
        </td>

        <td
          v-if="claim.capture_claim_amount"
          class="text-right"
        >
          {{ australianCurrency(totalCosts.trades.current_claim) }}
        </td>

        <td class="text-right">
          {{ australianCurrency(totalCosts.trades.current_certified) }}
        </td>

        <td
          v-if="claim.capture_claim_amount"
          class="text-right pr-3 text-red-600"
        >
          {{ australianCurrency(totalCosts.trades.variance) }}
        </td>

        <td class="text-right">
          {{ australianCurrency(totalCosts.trades.previous) }}
        </td>

        <td class="text-right pr-3">
          {{ australianCurrency(totalCosts.trades.cumulativeCertified) }}
        </td>

        <td
          v-if="claim.capture_claim_amount"
          class="text-right"
        >
          {{ isNumber(totalCosts.trades.current_claim / totalCosts.trades.budget) ? australianNumber((totalCosts.trades.current_claim / totalCosts.trades.budget) * 100) : 0 }}%
        </td>

        <td class="text-right">
          {{ isNumber(totalCosts.trades.current_certified / totalCosts.trades.budget) ? australianNumber((totalCosts.trades.current_certified / totalCosts.trades.budget) * 100) : 0 }}%
        </td>

        <td class="text-right">
          {{ isNumber(totalCosts.trades.previous / totalCosts.trades.budget) ? australianNumber((totalCosts.trades.previous / totalCosts.trades.budget) * 100) : 0 }}%
        </td>

        <td class="text-right pr-3">
          {{ isNumber(totalCosts.trades.cumulativeCertified / totalCosts.trades.budget) ? australianNumber((totalCosts.trades.cumulativeCertified / totalCosts.trades.budget) * 100) : 0 }}%
        </td>

        <td class="text-right pr-3">
          {{ australianCurrency(Math.abs(totalCosts.trades.budget - totalCosts.trades.cumulativeCertified)) }}
        </td>
      </tr>

      <template
        v-for="(orders, key) in variationOrders"
        :key="key"
      >
        <VariationOrderRow
          :claim="claim"
          :variation-orders="orders"
          :title="VariationOrderTitles[key]"
          :variation-order-type="VariationOrderType[key]"
        />
      </template>

      <tr class="border-black border">
        <td class="pl-3">
          Subtotal
        </td>

        <td class="text-right pr-3">
          {{ australianCurrency(totalCosts.variationOrders.budget) }}
        </td>

        <td
          v-if="claim.capture_claim_amount"
          class="text-right"
        >
          {{ australianCurrency(totalCosts.variationOrders.current_claim) }}
        </td>

        <td class="text-right">
          {{ australianCurrency(totalCosts.variationOrders.current_certified) }}
        </td>

        <td
          v-if="claim.capture_claim_amount"
          class="text-right pr-3"
          :class="{ 'text-red-600': totalCosts.variationOrders.variance !== 0 }"
        >
          {{ australianCurrency(totalCosts.variationOrders.variance) }}
        </td>

        <td class="text-right">
          {{ australianCurrency(totalCosts.variationOrders.previous) }}
        </td>

        <td class="text-right pr-3">
          {{ australianCurrency(totalCosts.variationOrders.cumulativeCertified) }}
        </td>

        <td
          v-if="claim.capture_claim_amount"
          class="text-right"
        >
          {{ totalCosts.variationOrders.budget > 0 ? australianNumber((totalCosts.variationOrders.current_claim / totalCosts.variationOrders.budget) * 100) : 0 }}%
        </td>

        <td class="text-right">
          {{ totalCosts.variationOrders.budget > 0 ? australianNumber((totalCosts.variationOrders.current_certified / totalCosts.variationOrders.budget) * 100) : 0 }}%
        </td>

        <td class="text-right">
          {{ totalCosts.variationOrders.budget > 0 ? australianNumber((totalCosts.variationOrders.previous / totalCosts.variationOrders.budget) * 100) : 0 }}%
        </td>

        <td class="text-right pr-3">
          {{ totalCosts.variationOrders.budget > 0 ? australianNumber((totalCosts.variationOrders.cumulativeCertified / totalCosts.variationOrders.budget) * 100) : 0 }}%
        </td>

        <td class="text-right pr-3">
          {{ australianCurrency(Math.abs(totalCosts.variationOrders.budget - totalCosts.variationOrders.cumulativeCertified)) }}
        </td>
      </tr>

      <tr class="border-black border">
        <td class="pl-3">
          Grand Total
        </td>

        <td class="text-right pr-3">
          {{ australianCurrency(totalCosts.variationOrders.budget + totalCosts.trades.budget) }}
        </td>

        <td class="text-right">
          {{ australianCurrency(totalCosts.variationOrders.current_certified + totalCosts.trades.current_certified) }}
        </td>

        <td
          v-if="claim.capture_claim_amount"
          class="text-right"
        >
          {{ australianCurrency(totalCosts.variationOrders.current_certified + totalCosts.trades.current_certified) }}
        </td>

        <td
          v-if="claim.capture_claim_amount"
          class="text-right pr-3"
          :class="{ 'text-red-600': totalCosts.variationOrders.variance + totalCosts.trades.variance !== 0 }"
        >
          {{ australianCurrency(totalCosts.variationOrders.variance + totalCosts.trades.variance) }}
        </td>

        <td class="text-right">
          {{ australianCurrency(totalCosts.variationOrders.previous + totalCosts.trades.previous ) }}
        </td>

        <td class="text-right pr-3">
          {{ australianCurrency(totalCosts.variationOrders.cumulativeCertified + totalCosts.trades.cumulativeCertified) }}
        </td>

        <td
          v-if="claim.capture_claim_amount"
          class="text-right"
        >
          {{ totalCosts.variationOrders.budget + totalCosts.trades.budget > 0 ? australianNumber(((totalCosts.variationOrders.current_claim + totalCosts.trades.current_claim) / (totalCosts.variationOrders.budget + totalCosts.trades.budget)) * 100) : 0 }}%
        </td>

        <td class="text-right">
          {{ totalCosts.variationOrders.budget + totalCosts.trades.budget > 0 ? australianNumber(((totalCosts.variationOrders.current_certified + totalCosts.trades.current_certified) / (totalCosts.variationOrders.budget + totalCosts.trades.budget)) * 100) : 0 }}%
        </td>

        <td class="text-right">
          {{ totalCosts.variationOrders.budget + totalCosts.trades.budget > 0 ? australianNumber(((totalCosts.variationOrders.previous + totalCosts.trades.previous) / (totalCosts.variationOrders.budget + totalCosts.trades.budget)) * 100) : 0 }}%
        </td>

        <td class="text-right pr-3">
          {{ totalCosts.variationOrders.budget + totalCosts.trades.budget > 0 ? australianNumber(((totalCosts.variationOrders.cumulativeCertified + totalCosts.trades.cumulativeCertified) / (totalCosts.variationOrders.budget + totalCosts.trades.budget)) * 100) : 0 }}%
        </td>

        <td class="text-right pr-3">
          {{ australianCurrency(Math.abs(totalCosts.variationOrders.budget - totalCosts.variationOrders.cumulativeCertified) + Math.abs(totalCosts.trades.budget - totalCosts.trades.cumulativeCertified)) }}
        </td>
      </tr>
    </tbody>
  </table>
</template>

<script setup lang="ts">
import { australianCurrency, australianNumber, isNumber } from '@/helpers';
import VariationOrder, { VariationOrderTitles, VariationOrderType } from '@/models/VariationOrder';
import { useClaimsStore } from '@/store/claims';
import { useVariationOrdersStore } from '@/store/variationOrders';
import { Collection } from 'pinia-orm';
import { computed } from 'vue';
import TradeRow from './TradeRow.vue';
import VariationOrderRow from './VariationOrderRow.vue';

const props = defineProps<{
  claimId: string;
  projectId: string;
}>();

const claimsStore = useClaimsStore();

const claim = computed(() => {
  // TODO: 10/11/23 constrain variation orders to only those with cost for the current claim
  if(props.claimId) {
    const claimQuery = claimsStore.models.with('trades', (query) => {
      query.with('trade_items')
        .orderBy('sort_order');
    }).with('trade_item_costs').with('variation_orders').with('variation_order_costs').where(
      'project_id',
      parseInt(props.projectId),
    );

    return claimQuery.find(parseInt(props.claimId));
  }

  return undefined;
});

const totalCosts = computed(() => {
  const totals = {
    trades: {
      budget: 0,
      current_claim: 0,
      current_certified: 0,
      cumulativeCertified: 0,
      previous: 0,
      variance: 0,
    },

    variationOrders: {
      budget: 0,
      current_claim: 0,
      current_certified: 0,
      cumulativeCertified: 0,
      previous: 0,
      variance: 0,
    },
  };

  if(claim.value) {
    claim.value.trades?.forEach((trade) => {
      trade.trade_items.forEach((tradeItem) => {
        totals.trades.budget += tradeItem.budget;
        totals.trades.previous += tradeItem.previous(claim.value);
      });
    });

    claim.value.trade_item_costs.forEach((cost) => {
      totals.trades.current_claim += cost.current_claim || 0;
      totals.trades.current_certified += cost.current_certified;
      totals.trades.variance += cost.current_claim - cost.current_certified;
    });

    claim.value.variation_orders?.forEach((variationOrder) => {
      totals.variationOrders.budget += variationOrder.budget;
      totals.variationOrders.previous += variationOrder.previous(claim.value);
    });

    claim.value.variation_order_costs?.forEach((variationOrderCost) => {
      totals.variationOrders.current_claim += variationOrderCost.current_claim || 0;
      totals.variationOrders.current_certified += variationOrderCost.current_certified;
      totals.variationOrders.variance += variationOrderCost.current_claim - variationOrderCost.current_certified;
    });
  }

  totals.trades.cumulativeCertified = totals.trades.current_certified + totals.trades.previous;

  totals.variationOrders.cumulativeCertified = totals.variationOrders.current_certified +
    totals.variationOrders.previous;

  return totals;
});

const variationOrdersStore = useVariationOrdersStore();

const variationOrders = computed(() => {
  const costs: Partial<{ [k in keyof typeof VariationOrderType]: Collection<VariationOrder> }> = {};

  for(const key in VariationOrderType) {
    costs[key] = variationOrdersStore.models.where('project_id', parseInt(props.projectId)).where(
      'type',
      VariationOrderType[key],
    ).with('costs').get();
  }

  return costs;
});
</script>

<style scoped></style>
