<template>
  <div class="w-[90%] mx-auto flex items-center pb-16">
    <div class="bg-white p-2 mt-8 rounded-lg shadow-lg border border-gray-300 w-full">
      <div class="my-5 flex justify-center">
        <h1 class="text-3xl font-semibold justify-center">
          Daily Diary
        </h1>
      </div>

      <div class="items-center flex flex-col px-3">
        <RouterLink
          v-if="userStore.hasAccessToRoute('MobileDiaryEntry')"
          :to="{ name: 'MobileDiaryEntry' }"
          class="btn btn--primary-blue shadow-md w-full"
          data-test="new-entry"
        >
          Add Entry
        </RouterLink>

        <div class="w-full justify-center flex flex-col space-y-2 my-2">
          <AppSpinner
            v-if="fetchDiaryEntriesAction.is(States.LOADING)"
            class="p-4 mx-auto"
          />

          <ul
            v-else
            class="divide-y divide-gray-300 space-y-2"
          >
            <li
              v-for="diaryEntry in diaryEntries"
              :key="diaryEntry.id"
            >
              <RouterLink
                :to="{ name: 'MobileDiaryEntry', params: { diaryId: diaryEntry.id } }"
                class="flex line-clamp-3 space-y-1 justify-between items-center space-x-2"
              >
                <span class="flex flex-col mt-2">
                  <p>
                    {{ diaryEntry.createdAt.toLocaleString() }}
                    <span v-if="!userIsSiteManager && diaryEntry.user_id !== userStore.user.id">
                      - {{ diaryEntry.user.name }}
                    </span>
                  </p>

                  <p class="truncate w-60 whitespace-break-spaces">
                    {{ diaryEntry.body }}
                  </p>
                </span>

                <Icon
                  name="chevron-right"
                  class="w-6 h-6 flex-shrink-0"
                />
              </RouterLink>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { States, useStoreApiAction } from '@/composables/useStoreApiAction';
import { useDiaryEntriesStore } from '@/store/diaryEntries';
import { useNavStore } from '@/store/nav';
import { useUserStore } from '@/store/user';
import Swal from 'sweetalert2';
import { computed } from 'vue';

const props = defineProps<{
  projectId: string;
}>();

const userStore = useUserStore();
const diaryEntriesStore = useDiaryEntriesStore();
const fetchDiaryEntriesAction = useStoreApiAction(diaryEntriesStore.fetchDiaryEntries);

fetchDiaryEntriesAction.request(props.projectId).then((entries) => {
  console.log(entries);
}).catch((error) => {
  if(error?.response.status === 500 || error?.response.status === 403) {
    Swal.fire({
      icon: 'error',
      text: 'Failed to fetch diary entries.',
    });
  }
});

const diaryEntries = computed(() => {
  return diaryEntriesStore.models.where('project_id', parseInt(props.projectId)).with('user').orderBy(
    'created_at',
    'desc',
  ).get();
});

const navStore = useNavStore();

navStore.backRoute = { name: 'MobileProjectPage' };

const userIsSiteManager = computed(() => {
  return userStore.isRole(['site_manager'], false);
});
</script>

<style scoped></style>
