<template>
  <div class="flex justify-center h-full space-y-2.5 my-8">
    <div
      class="flex flex-col border border-gray-300 p-5 space-y-6 element-island container w-1/2"
    >
      <div class="flex justify-between">
        <h1 class="text-4xl">
          Diary Entries
        </h1>

        <button
          type="button"
          class="btn btn--secondary-blue"
          @click="newEntry"
        >
          Add Entry
        </button>
      </div>

      <div class="flex space-x-4 h-full">
        <div class="border border-gray-300 shadow-md rounded relative flex flex-col w-full">
          <div
            v-if="diaryEntries.length"
            class="overflow-y-auto relative flex-1"
          >
            <div class="absolute inset-0 overflow-y-auto p-4">
              <ul class="space-y-4">
                <li
                  v-for="diaryEntry in diaryEntries"
                  :key="diaryEntry.id"
                  class="cursor-pointer"
                  @click="selectEntry(diaryEntry.id)"
                >
                  <div class="flex flex-col">
                    <h3 class="font-semibold text-lg">
                      {{ diaryEntry.createdAt.toLocaleString() }} - {{ diaryEntry.user?.name }}
                    </h3>

                    <p class="line-clamp-3 whitespace-pre">
                      {{ diaryEntry.body }}
                    </p>
                  </div>
                </li>
              </ul>
            </div>
          </div>

          <AppSpinner
            v-else-if="fetchDiaryEntriesAction.is(States.LOADING)"
            class="m-auto p-4"
          />

          <div v-else>
            No entries logged.
          </div>
        </div>
      </div>
    </div>

    <DiaryEntryModal
      v-if="showModal"
      :entry="selectedEntry"
      @close="showModal = false"
    />
  </div>
</template>

<script setup lang="ts">
import DiaryEntryModal from '@/components/SelfPerform/DiaryDashboard/DiaryEntryModal.vue';
import { States, useStoreApiAction } from '@/composables/useStoreApiAction';
import { useDiaryEntriesStore } from '@/store/diaryEntries';
import { useUserStore } from '@/store/user';
import { computed, ref, watch } from 'vue';

const props = defineProps<{
  projectId: string;
}>();

const diaryEntriesStore = useDiaryEntriesStore();
const usersStore = useUserStore();
const fetchDiaryEntriesAction = useStoreApiAction(diaryEntriesStore.fetchDiaryEntries);

watch(() => props.projectId, (newValue) => {
  if(newValue) {
    fetchDiaryEntriesAction.request(props.projectId);
  }
}, { immediate: true });

const diaryEntries = computed(() => {
  return diaryEntriesStore.models.where('raise_level', false).where(
    'project_id',
    parseInt(props.projectId),
  ).where('user_id', usersStore.user.id).with('user').orderBy('created_at', 'desc').get();
});

const showModal = ref(false);
const selectedEntryId = ref();

const selectedEntry = computed(() => {
  return diaryEntriesStore.models.find(selectedEntryId.value);
});

const selectEntry = (id: Id) => {
  selectedEntryId.value = id;
  showModal.value = true;
};

const newEntry = () => {
  selectedEntryId.value = undefined;
  showModal.value = true;
};
</script>

<style scoped></style>
