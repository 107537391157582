<template>
  <Modal @close="$emit('close')">
    <template #header>
      <div class="w-full text-center p-2">
        <h1 class="text-2xl">
          Entry
        </h1>
      </div>
    </template>

    <template #body>
      <div class="min-w-md flex flex-col my-2 p-4 max-h-[30rem] overflow-y-auto w-full max-w-md">
        <div
          v-if="entry"
          class="text-xl font-semibold"
        >
          {{ entry.createdAt.toLocaleString() }} - {{ entry.user?.name }}
        </div>

        <div
          v-else
          class="text-xl font-semibold"
        >
          {{ (new Date()).toLocaleDateString() }}
        </div>

        <form
          v-if="!entry || (entry.user_id === userStore.user.id && recordIsToday)"
          class="flex flex-col space-y-4"
          @submit.prevent="saveEntry"
        >
          <div>
            <textarea
              v-model="entryForm.form.body"
              rows="15"
              name="body"
              class="form-input resize-none mt-4"
            />

            <span
              v-if="entryForm.hasErrors('body')"
              class="text-red-600 text-sm"
            >
              {{ entryForm.getErrors('body') }}
            </span>
          </div>
        </form>

        <div
          v-else-if="entry"
          class="break-all whitespace-break-spaces mt-2"
        >
          {{ entry.body }}
        </div>
      </div>
    </template>

    <template #footer>
      <div class="flex p-4 space-x-4">
        <button
          type="button"
          class="btn btn--secondary-blue"
          @click="$emit('close')"
        >
          Close
        </button>

        <button
          v-if="!entry || (entry.user_id === userStore.user.id && recordIsToday)"
          type="button"
          class="btn btn--primary-blue"
          :disabled="createDiaryEntryAction.is(States.LOADING) || updateDiaryEntryAction.is(States.LOADING)"
          @click="saveEntry"
        >
          {{ entry ? 'Update' : 'Create' }}
        </button>
      </div>
    </template>
  </Modal>
</template>

<script setup lang="ts">
import Modal from '@/components/Modal.vue';
import { useFormData } from '@/composables/useFormData';
import { States, useStoreApiAction } from '@/composables/useStoreApiAction';
import DiaryEntry from '@/models/DiaryEntry';
import { useDiaryEntriesStore } from '@/store/diaryEntries';
import { useProjectsStore } from '@/store/projects';
import { useUserStore } from '@/store/user';
import { DateTime } from 'luxon';
import Swal from 'sweetalert2';
import { computed, watch } from 'vue';

const props = defineProps<{
  entry?: DiaryEntry;
}>();

const emits = defineEmits<{
  close: [];
}>();

const userStore = useUserStore();
const diaryEntriesStore = useDiaryEntriesStore();
const projectsStore = useProjectsStore();

const project = computed(() => {
  return projectsStore.project;
});

const entryForm = useFormData({
  body: '',
  raise_level: false,
});

watch(() => props.entry, (newValue) => {
  if(newValue) {
    entryForm.setData(props.entry);
  }
}, { immediate: true });

const createDiaryEntryAction = useStoreApiAction(diaryEntriesStore.createDiaryEntry);
const updateDiaryEntryAction = useStoreApiAction(diaryEntriesStore.updateDiaryEntry);

const saveEntry = () => {
  if(props.entry) {
    updateDiaryEntryAction.request(props.entry.id, entryForm.form).then(() => {
      Swal.fire({
        icon: 'success',
        title: 'Entry updated.',
      }).then(() => {
        emits('close');
      });
    }).catch((error) => {
      entryForm.setErrors(error.data);
    });
  } else {
    Swal.fire({
      icon: 'question',
      title: 'Raise ticket priority?',
      showDenyButton: true,
      showConfirmButton: true,
      confirmButtonColor: 'green',
      confirmButtonText: 'Yes',
    }).then((result) => {
      if(result.isConfirmed) {
        entryForm.form.raise_level = true;
      }

      createDiaryEntryAction.request(project.value.id, entryForm.form).then(() => {
        entryForm.resetData();

        Swal.fire({
          icon: 'success',
          title: 'Entry created.',
        }).then(() => {
          emits('close');
        });
      }).catch((error) => {
        entryForm.setErrors(error.data);
      });
    });
  }
};

const recordIsToday = computed(() => {
  return props.entry.createdAt.hasSame(DateTime.local(), 'day');
});
</script>

<style scoped></style>
