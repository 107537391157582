import ClaimCertifiedCosts from '@/views/ClaimCertifiedCosts.vue';
import CostAtComplete from '@/views/CostAtComplete.vue';
import BillOfQuantities from '@/views/HeadContract/BillOfQuantities.vue';
import CashFlow from '@/views/HeadContract/CashFlow.vue';
import CashflowBaseline from '@/views/HeadContract/CashflowBaseline.vue';
import CashflowForecasting from '@/views/HeadContract/CashflowForecasting.vue';
import ProgressClaimsIndexWrapper from '@/views/HeadContract/ProgressClaimsIndexWrapper.vue';
import ProgressClaimsShowWrapper from '@/views/HeadContract/ProgressClaimsShowWrapper.vue';
import HeadContractSettings from '@/views/HeadContractSettings.vue';
import LabourDashboard from '@/views/LabourDashboard.vue';
import LineChart from '@/views/LineChart.vue';
import Login from '@/views/Login.vue';
import MobileDiaryEntry from '@/views/Mobile/MobileDiaryEntry.vue';
import MobileDiaryIndex from '@/views/Mobile/MobileDiaryIndex.vue';
import MobileLabourDashboard from '@/views/Mobile/MobileLabourDashboard.vue';
import MobileLabourRecord from '@/views/Mobile/MobileLabourRecord.vue';
import MobilePlantDashboard from '@/views/Mobile/MobilePlantDashboard.vue';
import MobilePlantRecord from '@/views/Mobile/MobilePlantRecord.vue';
import MobileProjectList from '@/views/Mobile/MobileProjectList.vue';
import MobileProjectPage from '@/views/Mobile/MobileProjectPage.vue';
import MobileQuantities from '@/views/Mobile/MobileQuantities.vue';
import NotFound from '@/views/NotFound.vue';
import PasswordReset from '@/views/PasswordReset.vue';
import PlantDashboard from '@/views/PlantDashboard.vue';
import ProjectDailyRates from '@/views/ProjectDailyRates.vue';
import ProjectDashboard from '@/views/ProjectDashboard.vue';
import ProjectSelector from '@/views/ProjectSelector.vue';
import ProjectSettings from '@/views/ProjectSettings.vue';
import Quantities from '@/views/Quantities.vue';
import ResetPassword from '@/views/ResetPassword.vue';
import DiaryDashboard from '@/views/SelfPerform/DiaryDashboard.vue';
import SelfPerformDashboard from '@/views/SelfPerformDashboard.vue';
import { createRouter, createWebHistory, RouteRecordRaw as VueRouteRecord } from 'vue-router';

declare module 'vue-router' {
  interface RouteMeta {
    footer?: true;
    title: string;
    guest?: true;
    mobile?: true;
    auth?: true;
    hideBackButton?: true;
    roles?: RoleTypes[];
    hideLogin?: true;
  }
}

const routes: VueRouteRecord[] = [
  {
    path: '/:pathMatch(.*)*',
    name: 'NotFound',
    component: NotFound,
    meta: { title: 'Not Found', mobile: true },
  },
  {
    path: '/login',
    component: Login,
    name: 'Login',
    meta: {
      title: 'Login',
      guest: true,
      mobile: true,
    },
  },
  {
    path: '/reset-password',
    component: ResetPassword,
    name: 'ResetPassword',
    meta: {
      title: 'Reset Password',
      guest: true,
      mobile: true,
    },
  },
  {
    path: '/password-reset',
    component: PasswordReset,
    name: 'PasswordReset',
    meta: {
      title: 'Reset Password',
      guest: true,
      mobile: true,
    },
  },
  {
    path: '/',
    component: ProjectDashboard,
    name: 'ProjectDashboard',
    meta: { title: 'Projects', auth: true },
  },
  {
    path: '/mobile/projects',
    component: MobileProjectList,
    name: 'MobileProjectList',
    props: true,
    meta: {
      title: 'Projects',
      auth: true,
      hideBackButton: true,
      mobile: true,
    },
  },
  {
    path: '/mobile/project/:projectId',
    component: MobileProjectPage,
    name: 'MobileProjectPage',
    props: true,
    meta: {
      title: 'Project',
      auth: true,
      mobile: true,
    },
  },
  {
    path: '/mobile/project/:projectId/diary',
    component: MobileDiaryIndex,
    name: 'MobileDiaryIndex',
    props: true,
    meta: {
      title: 'Diary Entries',
      auth: true,
      mobile: true,
      roles: ['admin', 'company_admin', 'site_manager', 'project_admin'],
    },
  },
  {
    path: '/mobile/project/:projectId/diary/entry/:diaryId?',
    component: MobileDiaryEntry,
    name: 'MobileDiaryEntry',
    props: true,
    meta: {
      title: 'Diary Entry',
      auth: true,
      mobile: true,
      roles: ['admin', 'company_admin', 'site_manager', 'project_admin'],
    },
  },
  {
    path: '/mobile/project/:projectId/plants',
    component: MobilePlantDashboard,
    name: 'MobilePlantDashboard',
    props: true,
    meta: {
      title: 'Plant Dashboard',
      auth: true,
      mobile: true,
      footer: true,
      roles: ['admin', 'company_admin', 'site_manager', 'project_admin', 'engineer'],
    },
  },
  {
    path: '/mobile/project/:projectId/labours',
    component: MobileLabourDashboard,
    name: 'MobileLabourDashboard',
    props: true,
    meta: {
      title: 'Labour Dashboard',
      auth: true,
      mobile: true,
      footer: true,
      roles: ['admin', 'company_admin', 'site_manager', 'project_admin', 'engineer'],
    },
  },
  {
    path: '/mobile/project/:projectId/plants/:plantId/record/:recordId?',
    component: MobilePlantRecord,
    name: 'MobilePlantRecord',
    props: true,
    meta: {
      title: 'Plant Record',
      auth: true,
      mobile: true,
      roles: ['admin', 'company_admin', 'site_manager', 'project_admin', 'engineer'],
    },
  },
  {
    path: '/mobile/project/:projectId/labours/:labourId/record/:recordId?',
    component: MobileLabourRecord,
    name: 'MobileLabourRecord',
    props: true,
    meta: {
      title: 'Labour Record',
      auth: true,
      mobile: true,
      roles: ['admin', 'company_admin', 'site_manager', 'project_admin', 'engineer'],
    },
  },
  {
    path: '/mobile/project/:projectId/rates',
    component: ProjectDailyRates,
    name: 'MobileDashboard',
    props: true,
    meta: {
      title: 'Dashboard',
      auth: true,
      mobile: true,
    },
  },
  {
    path: '/mobile/project/:projectId/quantities',
    component: MobileQuantities,
    name: 'MobileQuantities',
    props: true,
    meta: {
      title: 'Quantities',
      auth: true,
      mobile: true,
      roles: ['admin', 'company_admin', 'site_manager', 'project_admin', 'engineer'],
    },
  },
  {
    path: '/project/:projectId/settings',
    component: ProjectSettings,
    name: 'ProjectSettings',
    props: true,
    meta: {
      title: 'Project Settings',
      auth: true,
      roles: ['admin', 'company_admin', 'project_admin', 'contract_admin', 'contract_manager'],
    },
  },
  {
    path: '/project/:projectId/self-perform',
    name: 'SelfPerformParent',
    children: [
      {
        path: '',
        component: SelfPerformDashboard,
        name: 'SelfPerformDefault',
        props: true,
        meta: {
          title: 'Projects',
          auth: true,
          mobile: true,
        },
      },
      {
        path: 'chart',
        component: LineChart,
        name: 'LineChart',
        props: true,
        meta: {
          title: 'Production Rates Dashboard',
          auth: true,
          roles: [
            'admin',
            'engineer',
            'project_admin',
            'company_admin',
          ],
        },
      },
      {
        path: 'daily-rates',
        component: ProjectDailyRates,
        name: 'ProjectDailyRates',
        props: true,
        meta: {
          title: 'Projects',
          auth: true,
          mobile: true,
        },
      },
      {
        path: 'labours',
        component: LabourDashboard,
        name: 'LabourDashboard',
        props: true,
        meta: {
          title: 'Labour Dashboard',
          auth: true,
          roles: [
            'admin',
            'engineer',
            'project_admin',
            'company_admin',
          ],
        },
      },
      {
        path: 'plants',
        component: PlantDashboard,
        name: 'PlantDashboard',
        props: true,
        meta: {
          title: 'Plant Dashboard',
          auth: true,
          roles: [
            'admin',
            'engineer',
            'project_admin',
            'company_admin',
          ],
        },
      },
      {
        path: 'costs',
        component: CostAtComplete,
        name: 'CostAtComplete',
        props: true,
        meta: {
          title: 'Cost to Complete',
          auth: true,
          roles: [
            'admin',
            'engineer',
            'project_admin',
            'company_admin',
          ],
        },
      },
      {
        path: 'quantities',
        component: Quantities,
        name: 'Quantities',
        props: true,
        meta: {
          title: 'Quantities',
          auth: true,
          roles: [
            'admin',
            'engineer',
            'contract_admin',
            'contract_manager',
            'project_admin',
            'company_admin',
          ],
        },
      },
      {
        path: 'diary',
        component: DiaryDashboard,
        name: 'DiaryDashboard',
        props: true,
        meta: {
          title: 'Diary Dashboard',
          auth: true,
          roles: ['admin', 'company_admin', 'site_manager', 'project_admin'],
        },
      },
    ],
  },
  {
    path: '/project/:projectId/head-contract',
    name: 'HeadContractParent',
    children: [
      {
        path: '',
        component: ProgressClaimsIndexWrapper,
        name: 'HeadContractDefault',
        props: true,
        meta: {
          title: 'Progress Claims',
          auth: true,
          roles: ['admin', 'project_admin', 'contract_admin', 'contract_manager', 'company_admin'],
        },
      },
      // {
      //   path: 'progress-claims/:claimId?',
      //   component: ProgressClaims,
      //   name: 'ProgressClaims',
      //   props: true,
      //   meta: {
      //     title: 'Progress Claim',
      //     auth: true,
      //     roles: ['admin', 'project_admin', 'contract_admin', 'contract_manager', 'company_admin'],
      //   },
      // },
      {
        path: 'progress-claims',
        component: ProgressClaimsIndexWrapper,
        name: 'ProgressClaimsIndex',
        props: true,
        meta: {
          title: 'Progress Claim',
          auth: true,
          roles: ['admin', 'project_admin', 'contract_admin', 'contract_manager', 'company_admin'],
        },
      },
      {
        path: 'progress-claims/claim/:claimId',
        component: ProgressClaimsShowWrapper,
        name: 'ProgressClaims',
        props: true,
        meta: {
          title: 'Progress Claim',
          auth: true,
          roles: ['admin', 'project_admin', 'contract_admin', 'contract_manager', 'company_admin'],
        },
      },
      {
        path: 'bill-of-quantities',
        component: BillOfQuantities,
        name: 'BillOfQuantities',
        props: true,
        meta: {
          title: 'Bill Of Quantities',
          auth: true,
          roles: ['admin', 'project_admin', 'contract_admin', 'contract_manager', 'company_admin'],
        },
      },
      // {
      //   path: 'project-summary',
      //   component: ProjectSummary,
      //   name: 'ProjectSummary',
      //   props: true,
      //   meta: {
      //     title: 'ProjectSummary',
      //     auth: true,
      //     roles: ['admin', 'project_admin', 'contract_admin', 'company_admin'],
      //   },
      // },
      {
        path: 'cash-flow',
        component: CashFlow,
        name: 'CashFlow',
        props: true,
        meta: {
          title: 'CashFlow',
          auth: true,
          roles: ['admin', 'project_admin', 'contract_admin', 'contract_manager', 'company_admin'],
        },
      },
      {
        path: 'baseline-forecasting',
        component: CashflowBaseline,
        name: 'CashFlowBaseline',
        props: true,
        meta: {
          title: 'CashFlow Baseline Forecasting',
          auth: true,
          roles: ['admin', 'project_admin', 'contract_admin', 'contract_manager', 'company_admin'],
        },
      },
      {
        path: 'cashflow-forecasting',
        component: CashflowForecasting,
        name: 'CashflowForecasting',
        props: true,
        meta: {
          title: 'CashFlow Forecasting',
          auth: true,
          roles: ['admin', 'project_admin', 'contract_admin', 'contract_manager', 'company_admin'],
        },
      },
    ],
  },
  {
    path: '/claims/:claimId/certified',
    component: ClaimCertifiedCosts,
    name: 'ClaimCertifiedCosts',
    props: true,
    meta: { title: 'Claim Certified Costs', hideLogin: true },
  },
  {
    path: '/claims/:claimId/claim',
    component: ClaimCertifiedCosts,
    name: 'ClaimClaimCosts',
    props: true,
    meta: { title: 'Claim Costs', hideLogin: true },
  },
  {
    path: '/project/:projectId/settings/head-contract',
    component: HeadContractSettings,
    name: 'HeadContractSettings',
    props: true,
    meta: {
      title: 'Head Contract Settings',
      auth: true,
      roles: ['admin', 'project_admin', 'contract_admin', 'contract_manager', 'company_admin'],
    },
  },
  {
    path: '/home/:projectId?',
    component: ProjectSelector,
    name: 'ProjectSelector',
    props: true,
    meta: { title: 'Home', auth: true },
  },
];

const authenticatedRoutes = routes.filter((route) => route.meta?.auth).map((route) => {
  return route.name;
});

const guestRoutes = routes.filter((route) => route.meta?.guest).map((route) => {
  return route.name;
});

const mobileCompatibleRoutes = routes.filter((route) => route.meta?.mobile).map((route) => {
  return route.name;
});

export { authenticatedRoutes, guestRoutes, mobileCompatibleRoutes, routes };

export default createRouter({
  history: createWebHistory(),

  routes,

  scrollBehavior(_to, _from, _savedPosition) {
    return { top: 0 };
  },
});
