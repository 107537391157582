<template>
  <div class="!pt-0">
    <div class="flex flex-col max-h-screen pt-16 pb-2.5 min-h-screen space-y-2.5">
      <div class="flex items-stretch justify-between mt-2.5">
        <div class="flex space-x-2 element-island">
          <h1 class="font-semibold text-3xl">
            Cashflow
          </h1>
        </div>

        <div class="element-island flex items-center">
          Contract Sum {{ australianCurrency(totalBudget) }}
        </div>
      </div>

      <div class="element-island overflow-y-auto flex flex-col min-h-max flex-1">
        <div class="h-96 w-full">
          <canvas
            ref="lineChart"
          />
        </div>

        <div class="w-full flex flex-1">
          <div class="w-1/2 rounded-lg border border-gray-300 overflow-y-auto relative">
            <div class="absolute inset-0 overflow-y-auto">
              <table class="w-full">
                <thead>
                  <tr class="border-b border-gray-300 text-left first-child:pl-2 child:p-2 middle-child:px-2 last-child:pr-2 text-sm">
                    <th>
                      Claim
                    </th>

                    <th>
                      Claim Date
                    </th>

                    <th class="text-right">
                      Baseline Monthly
                    </th>

                    <th class="text-right">
                      Baseline Cumulative
                    </th>

                    <th class="text-right">
                      Forecast
                    </th>

                    <th class="text-right">
                      Forecast Cumulative
                    </th>

                    <th class="text-right">
                      Actual Monthly
                    </th>

                    <th class="text-right">
                      Actual Cumulative
                    </th>
                  </tr>
                </thead>

                <tbody>
                  <tr
                    v-for="(claim, i) in claims"
                    :key="claim.id"
                    class="odd:bg-gray-100 even:bg-white first-child:pl-2 child:p-2 middle-child:px-2 last-child:pr-2 border-b border-gray-300 text-sm"
                  >
                    <td>
                      <div class="flex space-x-2 items-center">
                        <p>
                          {{ claim.claim_number }}
                        </p>

                        <div
                          class="py-1 px-2 text-white rounded truncate"
                          :class="claimStatus(claim.status)"
                        >
                          {{ ClaimStatusTitles[enumKeyFromValue(ClaimStatus, claim.status)] }}
                        </div>
                      </div>
                    </td>

                    <td>
                      {{ claim.startDate?.toFormat('MMM-yyyy') }}
                    </td>

                    <td class="text-right">
                      {{ australianCurrency(baseline[i] ?? 0) }}
                    </td>

                    <td class="text-right">
                      {{ australianCurrency(baselineCumulative[i] ?? baselineCumulative[baselineCumulative.length - 1]) }}
                    </td>

                    <td class="text-right">
                      {{ australianCurrency(forecast[i] ?? 0) }}
                    </td>

                    <td class="text-right">
                      {{ australianCurrency(forecastCumulative[i] ?? forecastCumulative[forecastCumulative.length - 1]) }}
                    </td>

                    <td class="text-right">
                      {{ australianCurrency(claim.totalCertified) }}
                    </td>

                    <td class="text-right">
                      {{ australianCurrency(claimCertifiedCumulative[i]) }}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>

          <div class="w-1/2 flex flex-col items-center justify-start">
            <h3 class="text-center font-bold text-2xl">
              Work Complete
            </h3>

            <div class="w-full my-auto">
              <canvas
                ref="pieChart"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { useStoreApiAction } from '@/composables/useStoreApiAction';
import { australianCurrency, cumulativeSum, enumKeyFromValue, roundDecimals } from '@/helpers';
import Claim, { ClaimStatus, ClaimStatusTitles } from '@/models/Claim';
import Project from '@/models/Project';
import { useClaimsStore } from '@/store/claims';
import { useProjectsStore } from '@/store/projects';
import { useTradesStore } from '@/store/trades';
import { Chart } from 'chart.js';
import { DateTime } from 'luxon';
import { computed, onMounted, ref, watch } from 'vue';
import { useRouter } from 'vue-router';

const props = defineProps<{
  projectId: string;
}>();

const projectsStore = useProjectsStore();

const project = computed(() => {
  return projectsStore.project;
});

const router = useRouter();

watch(project, (newValue) => {
  if(newValue && !newValue.boq_locked) {
    router.push({ name: 'BillOfQuantities', params: { projectId: newValue.id } });
  }
}, { immediate: true });

const lineChart = ref();
const pieChart = ref();

const chartData = ref({
  labels: [],
  datasets: [
    {
      label: 'Forecast',
      data: [],
      fill: false,
      borderColor: 'blue',
      tension: 0.1,
      order: 2,
    },
    {
      label: 'Actual',
      data: [],
      fill: false,
      borderColor: 'orange',
      tension: 0.1,
      order: 1,
    },
    {
      label: 'Baseline',
      data: [],
      fill: false,
      borderColor: 'red',
      tension: 0.1,
      order: 3,
    },
  ],
});

const claimsStore = useClaimsStore();
const tradesStore = useTradesStore();
const fetchClaimsAction = useStoreApiAction(claimsStore.fetchClaims);
const fetchTradesAction = useStoreApiAction(tradesStore.fetchTrades);

watch(() => props.projectId, () => {
  fetchTradesAction.request(props.projectId).catch((error) => {
    console.log(error);
  });

  fetchClaimsAction.request(props.projectId).catch((error) => {
    console.log(error);
  });
}, { immediate: true });

const claims = computed(() => {
  return claimsStore.models.where('project_id', parseInt(props.projectId)).get();
});

const trades = computed(() => {
  return tradesStore.models.where('project_id', parseInt(props.projectId)).get();
});

const claimCertifiedCumulative = computed(() => {
  const cumulativeSum = ((sum) => (value: Claim) => sum += value.totalCertified)(0);

  return claims.value.map(cumulativeSum);
});

const baseline = computed(() => {
  if(!project.value) {
    return [];
  }

  // get project length
  const contractLength = project.value.contractLength;

  const monthsInProject = Array.from({ length: contractLength }, (_, i) => {
    const date = DateTime.fromJSDate(project.value.contractStartDate);

    return date.plus({ months: i });
  });

  const monthlyTotals = [];

  monthsInProject.forEach((projectMonth, i) => {
    monthlyTotals[i] = 0;

    trades.value.filter((trade) => {
      return trade.baseline_start_date && trade.baseline_end_date;
    }).forEach((trade) => {
      // console.log(
      //   projectMonth.toFormat('yyyy-MM-dd'),
      //   trade.baselineStart <= projectMonth,
      //   projectMonth <= trade.baselineEnd,
      // );

      if(trade.baselineStart <= projectMonth && projectMonth <= trade.baselineEnd) {
        // console.log(
        //   Math.round(Math.abs(trade.baselineStart.diff(projectMonth, ['months']).months)),
        //   trade.baseline_forecast[Math.round(Math.abs(trade.baselineStart.diff(projectMonth, ['months']).months))],
        //   trade.totalBudget,
        // );

        monthlyTotals[i] += roundDecimals(
          trade.baseline_forecast[Math.round(Math.abs(trade.baselineStart.diff(projectMonth, ['months']).months))] ??
            0,
        );
      }
    });
  });

  return monthlyTotals;
});

const baselineCumulative = computed(() => {
  return cumulativeSum(baseline.value);
});

const forecast = computed(() => {
  if(!project.value) {
    return [];
  }

  const contractLength = project.value.contractLength;

  const monthsInProject = Array.from({ length: contractLength }, (_, i) => {
    const date = DateTime.fromJSDate(project.value.contractStartDate);

    return date.plus({ months: i });
  });

  const monthlyTotals = [];

  monthsInProject.forEach((projectMonth, i) => {
    monthlyTotals[i] = 0;

    trades.value.filter((trade) => {
      return trade.forecast_start_date && trade.forecast_end_date && trade.forecast;
    }).forEach((trade) => {
      // console.log(
      //   projectMonth.toFormat('yyyy-MM-dd'),
      //   trade.baselineStart <= projectMonth,
      //   projectMonth <= trade.baselineEnd,
      // );

      if(trade.forecastStart <= projectMonth && projectMonth <= trade.forecastEnd) {
        // console.log(
        //   Math.round(Math.abs(trade.baselineStart.diff(projectMonth, ['months']).months)),
        //   trade.baseline_forecast[Math.round(Math.abs(trade.baselineStart.diff(projectMonth, ['months']).months))],
        //   trade.totalBudget,
        // );

        monthlyTotals[i] += roundDecimals(
          trade.forecast[Math.round(Math.abs(trade.forecastStart.diff(projectMonth, ['months']).months))] ??
            0,
        );
      }
    });
  });

  return monthlyTotals;
});

const forecastCumulative = computed(() => {
  return cumulativeSum(forecast.value);
});

let chart: Chart | undefined;
let workDoneChart: Chart<'pie'> | undefined;

const updateChartForecastData = (forecastCumulative: number[]) => {
  chart.data.datasets[0].data = forecastCumulative;

  chart.update();
};

const updateChartActualsData = (certifiedCumulative: number[]) => {
  chart.data.datasets[1].data = certifiedCumulative;

  chart.update();
};

const updateChartBaselineData = (baselineCumulative: number[]) => {
  // console.log(monthlyTotals, cumulativeSum(monthlyTotals));

  // for each month in project

  // if trade baseline start >= month && end <= month
  // sum baseline * budget at month index (diff between start and month)

  // cumulative sum on total sum

  chart.data.datasets[2].data = baselineCumulative;

  chart.update();
};

const updateForecastChartLabels = (project: Project) => {
  const contractLength = project.contractLength;

  const labels = Array.from({ length: contractLength }, (_, i) => {
    const date = DateTime.fromJSDate(project.contractStartDate);

    return date.plus({ months: i }).toFormat('MMM-yyyy');
  });

  chart.data.labels = labels;

  chart.update();
};

watch(lineChart, (newValue) => {
  if(newValue) {
    const ctx = lineChart.value.getContext('2d');

    if(chart !== undefined) {
      chart.destroy();
    }

    chart = new Chart(ctx, {
      type: 'line',
      data: chartData.value,
      options: {
        responsive: true,
        plugins: {
          legend: {
            position: 'bottom',
          },

          title: {
            display: false,
          },
        },

        maintainAspectRatio: false,

        scales: {
          y: {
            ticks: {},
          },
        },
      },
    });

    updateChartActualsData(claimCertifiedCumulative.value);

    if(project.value) {
      updateForecastChartLabels(project.value);
    }
  }
});

onMounted(() => {
  if(lineChart.value) {
    const ctx = lineChart.value.getContext('2d');

    chart = new Chart(ctx, {
      type: 'line',
      data: chartData.value,
      options: {
        responsive: true,
        plugins: {
          legend: {
            position: 'bottom',
          },

          title: {
            display: false,
          },
        },

        maintainAspectRatio: false,

        scales: {
          y: {
            ticks: {},
          },
        },
      },
    });

    updateChartActualsData(claimCertifiedCumulative.value);

    if(project.value) {
      updateForecastChartLabels(project.value);
    }
  }
});

watch(project, (newValue) => {
  if(newValue && chart) {
    updateForecastChartLabels(newValue);
  }
}, { immediate: true });

watch(claimCertifiedCumulative, (newValue) => {
  if(chart) {
    updateChartActualsData(newValue);
  }
}, { immediate: true });

watch(baselineCumulative, (newValue) => {
  if(chart) {
    updateChartBaselineData(newValue);
  }
}, { immediate: true });

watch(forecastCumulative, (newValue) => {
  if(chart) {
    updateChartForecastData(newValue);
  }
}, { immediate: true });

const claimCertifiedTotal = computed(() => {
  return claims.value.reduce((total, claim) => {
    if(claim.totalCertified) {
      total += claim.totalCertified;
    }

    return total;
  }, 0);
});

const totalBudget = computed(() => {
  return trades.value.reduce((total, current) => {
    return total + current.totalBudget;
  }, 0);
});

watch([claimCertifiedTotal, totalBudget], (newValue) => {
  workDoneChart.data.datasets[0] = {
    data: newValue,
    backgroundColor: [
      'rgb(0, 0, 255, 0.8)',
      'rgb(255, 0, 0, 0.8)',
    ],

    hoverOffset: 4,
  };

  workDoneChart.data.labels = [
    `Work Done ${australianCurrency(newValue[0])}`,
    `Total Work ${australianCurrency(newValue[1])}`,
  ];

  workDoneChart.update();
});

onMounted(() => {
  const pieChartCtx = pieChart.value.getContext('2d');

  const data = {
    labels: [
      `Work Done ${australianCurrency(claimCertifiedTotal.value)}`,
      `Total Work ${australianCurrency(totalBudget.value)}`,
    ],

    datasets: [{
      data: [claimCertifiedTotal.value, totalBudget.value],
      backgroundColor: [
        'rgb(0, 0, 255, 0.8)',
        'rgb(255, 0, 0, 0.8)',
      ],

      hoverOffset: 4,
    }],
  };

  workDoneChart = new Chart(pieChartCtx, {
    type: 'pie',
    data,
    options: {
      responsive: true,
      plugins: {
        legend: {
          position: 'bottom',
        },

        title: {
          display: false,
        },

        tooltip: {
          callbacks: {
            label(this, tooltipItem) {
              const total = tooltipItem.dataset.data.reduce((total, current) => total + current);

              return (tooltipItem.dataset.data[tooltipItem.dataIndex] / total).toFixed(2) + '%';
            },
          },
        },
      },

      maintainAspectRatio: false,
    },
  });
});

const claimStatus = (status: ObjectValues<typeof ClaimStatus>) => {
  switch (true) {
    case status === ClaimStatus.draft:
      return 'bg-red-600';

    case status === ClaimStatus.certified:
      return 'bg-green-600';

    default:
      return 'bg-orange-600';
  }
};
</script>

<style scoped></style>
