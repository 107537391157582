<template>
  <div class="flex flex-col items-center justify-center h-full space-y-2.5 my-auto">
    <div
      class="p-4 w-full sm:w-1/2
    sm:mx-auto sm:border sm:shadow-lg sm:rounded-md sm:mt-2 lg:w-1/4 bg-white"
    >
      <div class="flex space-x-10">
        <h1 class="text-3xl font-semibold hidden sm:block break-words my-auto">
          Set new Password
        </h1>
      </div>

      <form
        v-if="!success"
        class="flex flex-col space-y-2"
        @submit.prevent="resetPassword"
      >
        <AppInput
          v-model="passwordResetForm.form.token"
          type="hidden"
          name="token"
          readonly
        />

        <label>
          Email
          <AppInput
            v-model="passwordResetForm.form.email"
            class="read-only:bg-gray-200"
            type="email"
            name="email"
            :error="passwordResetForm.getErrors('email')"
            readonly
          />
        </label>

        <label>
          Password

          <AppInput
            v-model="passwordResetForm.form.password"
            type="password"
            name="password"
            :error="passwordResetForm.getErrors('password')"
            required
          />
        </label>

        <label>
          Password Confirmation
          <AppInput
            v-model="passwordResetForm.form.password_confirmation"
            type="password"
            name="password_confirmation"
            :error="passwordResetForm.getErrors('password_confirmation')"
            required
          />
        </label>

        <div class="flex w-full space-x-2">
          <button
            type="submit"
            class="w-1/2 flex items-center justify-center bg-blue-300 py-2 rounded-lg active:bg-blue-500"
          >
            {{ params.welcome ? 'Set Password' : 'Reset Password' }}
          </button>
        </div>
      </form>

      <div
        v-else
        class="flex flex-col text-center"
      >
        <p class="text-green-600 font-bold text-lg py-6">
          {{ params.welcome ? 'Password set, you may now log in.' : 'Password reset successfully' }}
        </p>

        <RouterLink
          :to="{ name: 'Login' }"
          class="flex items-center justify-center bg-blue-300 py-2 rounded-lg active:bg-blue-500"
        >
          Login
        </RouterLink>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { useFormData } from '@/composables/useFormData';
import AuthService from '@/services/AuthService';
import { ref } from 'vue';

const urlSearchParams = new URLSearchParams(window.location.search);
const params = Object.fromEntries(urlSearchParams.entries());

const passwordResetForm = useFormData({
  token: params.token,
  email: params.email,
  password: '',
  password_confirmation: '',
});

const success = ref(false);

const resetPassword = () => {
  AuthService.resetPassword(passwordResetForm.form).then(([data, error]) => {
    if(data) {
      success.value = true;

      return;
    }

    passwordResetForm.setErrors(error.data);
  });
};
</script>

<style scoped></style>
